@import "soft-design-system";

$hybe-yellow: #F5FF00;


.bg-hybe-yellow {
    background-color: $hybe-yellow;
}
.text-hybe-yellow {
    color: $hybe-yellow;
}

body {
    font-family: "Open sans", sans-serif;

    &.has-video {
        overflow:hidden;
    }
}

.btn {
    text-transform: initial;
}

.navbar {

    .navbar-collapse {
        box-shadow: rgba(#555, .2) 10px 10px 10px;

        &:not(.show) {
            transition: transform .2s ease-in-out, opacity .3s linear;
            display:block;
            transform: translate3d(0,-300px, 0);
            opacity:0;
            user-select:none;
        }
        &.show {
            transition: transform .4s ease-in-out, opacity .6s linear;
            transform: translate3d(0, 0, 0);
            opacity:1;
        }

    }

    .navbar-nav {
        &> li {
            &:hover {
                background:rgba(#000, .2);
                cursor:pointer;
            }
        }
    }

    .navbar-toggler {
        &:not(.collapsed) {
            & > span{
                background:rgba(#FFF,1);
            }
        }

    }
}


/* LKTB Bridge header */
header.lktb-bridge {

    @include media-breakpoint-up(sm) {
        & > .page-header {
            min-height:40vh;
        }
    }

}


/* RGBA White backgrounds */

@for $i from 0 through 10 {
    .bg-white-#{$i}0 {
        background-color: rgba(#FFF, $i * 0.1);
    }
}

/* Youtube embed */
.video-responsive {
    overflow:hidden;
    z-index:3;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    height:auto;
    width:auto;
    background: rgba(#000, .8);

    display:flex;
    justify-content:center;
    align-items:center;

    transition: transform .5s ease-in-out;

    &:not(.show) {
        transform:translate3d(-4444px, 0, 0);
    }

    &.show {
        transform:translate3d(0, 0, 0);
    }

    iframe {
        max-width:800px;
        max-height:450px;
    }
}


/* Loading screen */
section.loading {

    min-height:40vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

/* BPYK */
.bpyk-bridge {

    .bridge-link {
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius: 20px;
        padding:2em;

        &.bilibili {
            border-color: #07a3d7!important;
            & img {
                max-width:49%;
                height:auto;
            }
        }

        &.youtube { 
            border-color: #ff0000;
        }

    }
    
}

